<template>
    <div class="px-3">
        <div class="text-start py-2 pt-4">
            <div class="text-center text-rt-yellow">
                <font class="h3 font-1-e-bold">
                    TERMS AND CONDITIONS
                </font><br>
                <font class="text-gray font-1-s-bold h6">
                    Last updated[April 8, 2022]
                </font>
            </div>
        </div>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">This document is an electronic record in
            terms of
            National Information Technology Development Agency Act, 2007 and rules there under as applicable and the
            amended
            provisions pertaining to electronic records in various statutes as amended by the National Information
            Technology Development Agency Act, 2007. This electronic record is generated by a computer system and does
            not
            require any physical or digital signatures.</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Your use of this website www………………. and tools
            are
            governed by the following terms and conditions as applicable to RENTOUT. If you transact on RENTOUT, you
            shall
            be subject to the policies that are applicable to the website for such transaction. By mere use of the
            website,
            you shall be contracting with RENOUT LTD, a company incorporated under Companies and Allied Matters Act,
            2021
            with head office at</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">………………………………………………………………………….and these terms
            and
            conditions constitute your binding obligations.</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">For the purpose of these terms of use,
            wherever
            the context so require, ‘You’ shall mean any natural or legal person who has agreed to become a member of
            the
            Website by providing Registration Data while registering on the Website as a Registered User.</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">When you use any of the services provided by
            RENTOUT LTD, including but not limited to, (e.g., Customer Reviews), you will be subject to the rules,
            guidelines, policies, terms, and conditions applicable to such service, and they shall be deemed to be
            incorporated into this Terms of Use and shall be considered as part and parcel of this Terms of Use. RENTOUT
            LTD
            reserves the right, at its sole discretion, to change, modify, add or remove portions of these Terms of Use,
            at
            any time. It is your responsibility to check these Terms of Use periodically for changes. Your continued use
            of
            the Site following the posting of changes will mean that you accept and agree to the changes. As long as you
            comply with these Terms of Use, RENTOUT LTD grants you a personal, non-exclusive, non-transferable, limited
            privilege to enter and use the Site</p>
        <h2 style="padding-left: 7pt;text-indent: 0pt;line-height: 13pt;text-align: justify;">Membership Eligibility
        </h2>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Use of this website is available only to
            persons
            who can form legally binding contracts under Nigerian governing laws. If you are a minor i.e., under the age
            of
            18 years, you shall not register as a member of RENTOUT LTD and shall not transact or use RENTOUT LTD
            website.
            As a minor if you wish to use or transact on RENTOUT LTD, such use or transaction may be made by your legal
            guardian or parents who have registered as users of RENTOUT LTD. RENTOUT LTD reserves the right to terminate
            your membership and refuse to provide you with access to RENTOUT LTD if it is brought to RENTOUT LTD’s
            notice or
            if it is discovered that you are under the age of 18 years.</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Membership on RENTOUT LTD is free. RENTOUT
            LTD
            does not charge any fee for browsing and buying on RENTOUT LTD. However, RENTOUT LTD reserves the right to
            charge a fee and change its policies from time to time. In particular, RENTOUT LTD may at its sole
            discretion
            introduce new services and modify some or all of the existing services offered on RENTOUT LTD. In such an
            event
            RENTOUT LTD reserves, without notice to You, the right to introduce fees for the new services offered or
            amend/introduce fees for existing services, as the case may be. Changes to the Fee and related policies
            shall
            automatically become effective immediately once implemented on RENTOUT LTD. Unless otherwise stated, all
            fees
            shall be quoted in Nigerian Naira. You shall be solely responsible for compliance of all applicable laws
            including those in Nigeria for making payments to RENTOUT LTD.</p>
        <p style="text-indent: 0pt;text-align: left;"><br /></p>
        <p style="padding-left: 5pt;text-indent: 0pt;line-height: 1pt;text-align: left;" />
        <h2 style="padding-top: 3pt;padding-left: 7pt;text-indent: 0pt;line-height: 14pt;text-align: left;">1.0
            AGREEMENT TO
            TERMS</h2>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">These Terms and Conditions constitute a
            legally
            binding agreement made between you, whether personally or on behalf of an entity (“you”) and [RENTOUT LTD]
            (“we,” “us” or “our”), concerning your access to and use of the [<b>website name.com</b>] website as well as
            any
            other media form, media channel, mobile website or mobile application related, linked, or otherwise
            connected
            thereto (collectively, the “Site”).</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">You agree that by accessing the Site, you
            have
            read, understood, and agree to be bound by all of these Terms and Conditions. If you do not agree with all
            of
            these Terms and Conditions, then you are expressly prohibited from using the Site and you must discontinue
            use
            immediately.</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Supplemental terms and conditions or
            documents
            that may be posted on the Site from time to time are hereby expressly incorporated herein by reference. We
            reserve the right, in our sole discretion, to make changes or modifications to these Terms and Conditions at
            any
            time and for any reason.</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">We will alert you about any changes by
            updating
            the “Last updated” date of these Terms and Conditions, and you waive any right to receive specific notice of
            each such change.</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">It is your responsibility to periodically
            review
            these Terms and Conditions to stay informed of updates. You will be subject to, and will be deemed to have
            been
            made aware of and to have accepted, the changes in any revised Terms and Conditions by your continued use of
            the
            Site after the date such revised Terms and Conditions are posted.</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">The information provided on the Site is not
            intended for distribution to or use by any person or entity in any jurisdiction or country where such
            distribution or use would be contrary to law or regulation or which would subject us to any registration
            requirement within such jurisdiction or country.</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Accordingly, those persons who choose to
            access
            the Site from other locations do so on their own initiative and are solely responsible for compliance with
            local
            laws, if and to the extent local laws are applicable.</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">This Site/application is intended for users
            who
            are at least 18 years old. Persons under the age of 18 are not permitted to register for the Site.</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">All users who are minors in the jurisdiction
            in
            which they reside (generally under the age of 18) must have the permission of, and be directly supervised
            by,
            their parent or guardian to use the Site. If you are a minor, you must have your parent or guardian read and
            agree to these Terms and Conditions prior to you using the Site.</p>
        <h2 style="padding-left: 7pt;text-indent: 0pt;line-height: 13pt;text-align: left;">2.0 INTELLECTUAL PROPERTY
            RIGHTS
        </h2>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Unless otherwise indicated, we retain all
            right,
            title, and interest in and to the Software and the Website, including without limitation all graphics, user
            interfaces, databases, functionality, software, website designs, audio, video, text, photographs, graphics,
            logos, and trademarks or service marks reproduced through the System. These Terms of Use do not grant you
            any
            intellectual property license or rights in or to the Software and the Website or any of its components,
            except
            to the limited extent that these Terms of Use specifically sets forth your license rights to it. You
            recognize
            that the Software and the Website and their components are protected by copyright and other laws.</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Conditioned upon your compliance with these
            Terms
            of Use and, if applicable, your payments, we grant you the rights to reproduce, modify, and publicly display
            the
            Forms and use the Consent Platform (all together “Content”) on your website or mobile application. In
            addition,
            as to a Form intended to serve as a legal agreement between you and a counterparty, we grant you the right
            to
            distribute that Form to the counterparty to the extent reasonably necessary for your counterparty to execute
            the
            agreement. Under no circumstances may you use the Forms and use the Consent Platform for commercial purposes
            other than to facilitate compliance with applicable laws and best practices or the execution of agreements
            by
            your own business or organization. The Forms and the Consent Platform generated by the Software are
            licensed,
            not sold, and you receive no title to or ownership of the Forms or the Consent Platform.</p>
        <p style="text-indent: 0pt;text-align: left;"><br /></p>
        <p style="padding-left: 5pt;text-indent: 0pt;line-height: 1pt;text-align: left;" />
        <p style="padding-top: 3pt;padding-left: 7pt;text-indent: 0pt;text-align: justify;">You and Rentout Ltd agree
            that
            none of the Feedback (as defined below) you or your users provide to us constitute confidential information,
            and
            nothing in these Terms of Use or in our dealings arising out of or related to subject matter of these Terms
            of
            Use will restrict our right to use, profit from, disclose, publish, keep secret, or otherwise exploit
            Feedback,
            without compensating or crediting you or the user in question. You hereby grant us a perpetual, irrevocable
            right and license to exploit the Feedback in any and every way and subject to applicable law waive any moral
            rights in such Feedback. Feedback will not constitute your confidential information. (“Feedback” refers to
            any
            suggestion or idea for improving or otherwise modifying any of Rentout Ltd.’s products or services.)</p>
        <ol id="l1">
            <ol id="l2">
                <li data-list-text="3.0">
                    <h2 style="padding-left: 24pt;text-indent: -17pt;line-height: 13pt;text-align: left;">USER
                        REPRESENTATIONS</h2>
                    <p style="padding-left: 7pt;text-indent: 0pt;line-height: 14pt;text-align: left;">By using the site
                        and
                        its services, you represent and warrant that:</p>
                    <ul id="l3">
                        <li data-list-text="">
                            <p style="padding-left: 43pt;text-indent: -18pt;text-align: left;">all registration
                                information
                                you submit will be true, accurate, current, and complete;</p>
                        </li>
                        <li data-list-text="">
                            <p style="padding-left: 43pt;text-indent: -18pt;text-align: left;">you will maintain the
                                accuracy of such information and promptly update such registration information as
                                necessary;]</p>
                        </li>
                        <li data-list-text="">
                            <p style="padding-left: 43pt;text-indent: -18pt;text-align: left;">you have the legal
                                capacity
                                and you agree to comply with these Terms and Conditions;</p>
                        </li>
                        <li data-list-text="">
                            <p style="padding-left: 43pt;text-indent: -18pt;text-align: left;">you are not under the age
                                of
                                18;]</p>
                        </li>
                        <li data-list-text="">
                            <p style="padding-left: 43pt;text-indent: -18pt;text-align: left;">not a minor in the
                                jurisdiction in which you reside [, or if a minor, you have received parental permission
                                to
                                use the Site];</p>
                        </li>
                        <li data-list-text="">
                            <p style="padding-top: 1pt;padding-left: 43pt;text-indent: -18pt;text-align: left;">you will
                                not
                                access the Site through automated or non-human means, whether through a bot, script, or
                                otherwise;</p>
                        </li>
                        <li data-list-text="">
                            <p style="padding-left: 43pt;text-indent: -18pt;text-align: left;">you will not use the Site
                                for
                                any illegal or unauthorized purpose;</p>
                        </li>
                        <li data-list-text="">
                            <p style="padding-left: 43pt;text-indent: -18pt;text-align: left;">your use of the Site will
                                not
                                violate any applicable law or regulation.</p>
                        </li>
                    </ul>
                </li>
            </ol>
        </ol>
        <p style="padding-top: 13pt;padding-left: 7pt;text-indent: 0pt;text-align: justify;">If you provide any
            information
            that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your
            account
            and refuse any and all current or future use of the Site (or any portion thereof).</p>
        <h2 style="padding-left: 7pt;text-indent: 0pt;line-height: 13pt;text-align: left;">4.0 USER REGISTRATION [Your
            Account and Registration Obligations]</h2>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">You <b>may </b>be required to register with
            the
            Site. You agree to keep your password confidential and will be responsible for all use of your account and
            password. We reserve the right to remove, reclaim, or change a username you select if we determine, in our
            sole
            discretion, that such username is inappropriate, obscene, or otherwise objectionable.</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">If you use RENTOUT LTD, you shall be
            responsible
            for maintaining the confidentiality of your User ID and Password and you shall be responsible for all
            activities
            that occur under your User ID and Password. You agree that if you provide any information that is untrue,
            inaccurate, not current or incomplete that RENTOUT LTD has reasonable grounds to suspect that such
            information
            is untrue, inaccurate, not current or incomplete, or not in accordance with this Terms of Use, RENTOUT LTD
            has
            the right to indefinitely suspend or terminate or block access of your membership with RENTOUT LTD and
            refuse to
            provide you with access to the Website.</p>
        <ol id="l4">
            <ol id="l5">
                <li data-list-text="5.0">
                    <h2 style="padding-left: 24pt;text-indent: -17pt;line-height: 13pt;text-align: left;">PROHIBITED
                        ACTIVITIES</h2>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">You may not access or use the
                        Site
                        for any purpose other than that for which we make the Site available. The Site may not be used
                        in
                        connection with any commercial endeavors except those that are specifically endorsed or approved
                        by
                        us.</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;line-height: 13pt;text-align: justify;">As a user of
                        the
                        Site, you agree not to:</p>
                    <ol id="l6">
                        <li data-list-text="1.">
                            <p style="padding-left: 35pt;text-indent: -21pt;text-align: justify;">systematically
                                retrieve
                                data or other content from the Site to create or compile, directly or indirectly, a
                                collection, compilation, database, or directory without written permission from us.</p>
                        </li>
                        <li data-list-text="2.">
                            <p style="padding-left: 35pt;text-indent: -21pt;text-align: justify;">make any unauthorized
                                use
                                of the Site, including collecting usernames and/or email addresses of users by
                                electronic or
                                other means for the purpose of sending unsolicited email, or creating user accounts by
                                automated means or under false pretenses.</p>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>
                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 1pt;text-align: left;" />
                        </li>
                        <li data-list-text="3.">
                            <p
                                style="padding-top: 3pt;padding-left: 35pt;text-indent: -21pt;line-height: 14pt;text-align: justify;">
                                use a buying agent or purchasing agent to make purchases on the Site.</p>
                        </li>
                        <li data-list-text="4.">
                            <p style="padding-left: 35pt;text-indent: -21pt;line-height: 13pt;text-align: justify;">use
                                the
                                Site to advertise or offer to sell goods and services.</p>
                        </li>
                        <li data-list-text="5.">
                            <p style="padding-left: 35pt;text-indent: -21pt;text-align: justify;">circumvent, disable,
                                or
                                otherwise interfere with security-related features of the Site, including features that
                                prevent or restrict the use or copying of any Content or enforce limitations on the use
                                of
                                the Site and/or the Content contained therein.</p>
                        </li>
                        <li data-list-text="6.">
                            <p style="padding-left: 35pt;text-indent: -21pt;line-height: 13pt;text-align: justify;">
                                engage
                                in unauthorized framing of or linking to the Site.</p>
                        </li>
                        <li data-list-text="7.">
                            <p style="padding-left: 35pt;text-indent: -21pt;text-align: justify;">trick, defraud, or
                                mislead
                                us and other users, especially in any attempt to learn sensitive account information
                                such as
                                user passwords;</p>
                        </li>
                        <li data-list-text="8.">
                            <p style="padding-left: 35pt;text-indent: -21pt;line-height: 14pt;text-align: justify;">make
                                improper use of our support services or submit false reports of abuse or misconduct.</p>
                        </li>
                        <li data-list-text="9.">
                            <p style="padding-left: 35pt;text-indent: -21pt;text-align: left;">engage in any automated
                                use
                                of the system, such as using scripts to send comments or messages, or using any data
                                mining,
                                robots, or similar data gathering and extraction tools.</p>
                        </li>
                        <li data-list-text="10.">
                            <p style="padding-left: 35pt;text-indent: -21pt;text-align: left;">interfere with, disrupt,
                                or
                                create an undue burden on the Site or the networks or services connected to the Site.
                            </p>
                        </li>
                        <li data-list-text="11.">
                            <p style="padding-left: 35pt;text-indent: -21pt;line-height: 13pt;text-align: left;">attempt
                                to
                                impersonate another user or person or use the username of another user.</p>
                        </li>
                        <li data-list-text="12.">
                            <p style="padding-left: 35pt;text-indent: -21pt;line-height: 13pt;text-align: left;">sell or
                                otherwise transfer your profile.</p>
                        </li>
                        <li data-list-text="13.">
                            <p style="padding-left: 35pt;text-indent: -21pt;line-height: 14pt;text-align: left;">use any
                                information obtained from the Site in order to harass, abuse, or harm another person.
                            </p>
                        </li>
                        <li data-list-text="14.">
                            <p style="padding-left: 35pt;text-indent: -21pt;text-align: left;">use the Site as part of
                                any
                                effort to compete with us or otherwise use the Site and/or the Content for any
                                revenue-generating endeavor or commercial enterprise.</p>
                        </li>
                        <li data-list-text="15.">
                            <p style="padding-left: 35pt;text-indent: -21pt;text-align: left;">decipher, decompile,
                                disassemble, or reverse engineer any of the software comprising or in any way making up
                                a
                                part of the Site.</p>
                        </li>
                        <li data-list-text="16.">
                            <p style="padding-left: 35pt;text-indent: -21pt;text-align: left;">attempt to bypass any
                                measures of the Site designed to prevent or restrict access to the Site, or any portion
                                of
                                the Site.</p>
                        </li>
                        <li data-list-text="17.">
                            <p style="padding-left: 35pt;text-indent: -21pt;text-align: left;">harass, annoy,
                                intimidate, or
                                threaten any of our employees or agents engaged in providing any portion of the Site to
                                you.
                            </p>
                        </li>
                        <li data-list-text="18.">
                            <p style="padding-left: 35pt;text-indent: -21pt;line-height: 14pt;text-align: left;">delete
                                the
                                copyright or other proprietary rights notice from any Content.</p>
                        </li>
                        <li data-list-text="19.">
                            <p style="padding-left: 35pt;text-indent: -21pt;line-height: 13pt;text-align: left;">copy or
                                adapt the Site’s software, including but not limited to Flash, PHP, HTML, JavaScript,
                            </p>
                            <p style="padding-left: 35pt;text-indent: 0pt;line-height: 13pt;text-align: left;">or other
                                code.</p>
                        </li>
                        <li data-list-text="20.">
                            <p style="padding-left: 35pt;text-indent: -21pt;text-align: justify;">upload or transmit (or
                                attempt to upload or to transmit) viruses, Trojan horses, or other material, including
                                excessive use of capital letters and spamming (continuous posting of repetitive text),
                                that
                                interferes with any party’s uninterrupted use and enjoyment of the Site or modifies,
                                impairs, disrupts, alters, or interferes with the use, features, functions, operation,
                                or
                                maintenance of the Site.</p>
                        </li>
                        <li data-list-text="21.">
                            <p style="padding-left: 35pt;text-indent: -21pt;text-align: justify;">upload or transmit (or
                                attempt to upload or to transmit) any material that acts as a passive or active
                                information
                                collection or transmission mechanism, including without limitation, clear graphics
                                interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or other similar devices
                                (sometimes referred to as “spyware” or “passive collection mechanisms” or “pcms”).</p>
                        </li>
                        <li data-list-text="22.">
                            <p style="padding-left: 35pt;text-indent: -21pt;text-align: justify;">except as may be the
                                result of standard search engine or Internet browser usage, use, launch, develop, or
                                distribute any automated system, including without limitation, any spider, robot, cheat
                                utility, scraper, or offline reader that accesses the Site, or using or launching any
                                unauthorized script or other software.</p>
                        </li>
                        <li data-list-text="23.">
                            <p style="padding-left: 35pt;text-indent: -21pt;line-height: 14pt;text-align: justify;">
                                disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site.</p>
                        </li>
                        <li data-list-text="24.">
                            <p style="padding-left: 35pt;text-indent: -21pt;line-height: 13pt;text-align: justify;">use
                                the
                                Site in a manner inconsistent with any applicable laws or regulations etc.</p>
                        </li>
                    </ol>
                </li>
            </ol>
        </ol>
        <ol id="l7">
            <ol id="l8">
                <li data-list-text="6.0">
                    <h2 style="padding-left: 24pt;text-indent: -17pt;line-height: 13pt;text-align: left;">USER GENERATED
                        CONTRIBUTIONS</h2>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">The Site may invite you to chat,
                        contribute to, or participate in blogs, message boards, online forums, and other functionality,
                        and
                        may provide you with the opportunity to create, submit, post, display, transmit, perform,
                        publish,
                        distribute, or broadcast content and materials to us or on the Site, including but not limited
                        to
                        text, writings, video, audio, photographs, graphics, comments, suggestions, or personal
                        information
                        or other material (collectively, &quot;Contributions&quot;).</p>
                    <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 1pt;text-align: left;" />
                    <p style="padding-top: 3pt;padding-left: 7pt;text-indent: 0pt;text-align: justify;">Contributions
                        may be
                        viewable by other users of the Site and through third-party websites. As such, any Contributions
                        you
                        transmit may be treated as non-confidential and non-proprietary. When you create or make
                        available
                        any Contributions, you thereby represent and warrant that:</p>
                    <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    <ol id="l9">
                        <li data-list-text="1.">
                            <p style="padding-left: 34pt;text-indent: -18pt;text-align: justify;">the creation,
                                distribution, transmission, public display, or performance, and the accessing,
                                downloading,
                                or copying of your Contributions do not and will not infringe the proprietary rights,
                                including but not limited to the copyright, patent, trademark, trade secret, or moral
                                rights
                                of any third party.</p>
                        </li>
                        <li data-list-text="2.">
                            <p style="padding-left: 34pt;text-indent: -18pt;text-align: justify;">you are the creator
                                and
                                owner of or have the necessary licenses, rights, consents, releases, and permissions to
                                use
                                and to authorize us, the Site, and other users of the Site to use your Contributions in
                                any
                                manner contemplated by the Site and these Terms and Conditions.</p>
                        </li>
                        <li data-list-text="3.">
                            <p style="padding-left: 34pt;text-indent: -18pt;text-align: justify;">you have the written
                                consent, release, and/or permission of each and every identifiable individual person in
                                your
                                Contributions to use the name or likeness of each and every such identifiable individual
                                person to enable inclusion and use of your Contributions in any manner contemplated by
                                the
                                Site and these Terms and Conditions.</p>
                        </li>
                        <li data-list-text="4.">
                            <p style="padding-left: 34pt;text-indent: -18pt;line-height: 13pt;text-align: justify;">your
                                Contributions are not false, inaccurate, or misleading.</p>
                        </li>
                        <li data-list-text="5.">
                            <p style="padding-left: 34pt;text-indent: -18pt;text-align: left;">your Contributions are
                                not
                                unsolicited or unauthorized advertising, promotional materials, pyramid schemes, chain
                                letters, spam, mass mailings, or other forms of solicitation.</p>
                        </li>
                        <li data-list-text="6.">
                            <p style="padding-left: 34pt;text-indent: -18pt;text-align: left;">your Contributions are
                                not
                                obscene, lewd, lascivious, filthy, violent, harassing, libelous, slanderous, or
                                otherwise
                                objectionable (as determined by us).</p>
                        </li>
                        <li data-list-text="7.">
                            <p style="padding-left: 34pt;text-indent: -18pt;line-height: 14pt;text-align: left;">your
                                Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.</p>
                        </li>
                        <li data-list-text="8.">
                            <p style="padding-left: 34pt;text-indent: -18pt;text-align: left;">your Contributions do not
                                advocate the violent overthrow of any government or incite, encourage, or threaten
                                physical
                                harm against another.</p>
                        </li>
                        <li data-list-text="9.">
                            <p style="padding-left: 34pt;text-indent: -18pt;line-height: 14pt;text-align: left;">your
                                Contributions do not violate any applicable law, regulation, or rule.</p>
                        </li>
                        <li data-list-text="10.">
                            <p style="padding-left: 34pt;text-indent: -18pt;line-height: 14pt;text-align: left;">your
                                Contributions do not violate the privacy or publicity rights of any third party.</p>
                        </li>
                        <li data-list-text="11.">
                            <p style="padding-left: 34pt;text-indent: -18pt;text-align: left;">your Contributions do not
                                contain any material that solicits personal information from anyone under the age of 18
                                or
                                exploits people under the age of 18 in a sexual or violent manner.</p>
                        </li>
                        <li data-list-text="12.">
                            <p style="padding-left: 34pt;text-indent: -18pt;text-align: left;">your Contributions do not
                                violate any federal or state law concerning child pornography, or otherwise intended to
                                protect the health or well-being of minors;</p>
                        </li>
                        <li data-list-text="13.">
                            <p style="padding-left: 34pt;text-indent: -18pt;text-align: left;">your Contributions do not
                                include any offensive comments that are connected to race, national origin, gender,
                                sexual
                                preference, or physical handicap.</p>
                        </li>
                        <li data-list-text="14.">
                            <p style="padding-left: 34pt;text-indent: -18pt;text-align: left;">your Contributions do not
                                otherwise violate, or link to material that violates, any provision of these Terms and
                                Conditions, or any applicable law or regulation.</p>
                        </li>
                    </ol>
                </li>
            </ol>
        </ol>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: left;">Any use of the Site in violation of the
            foregoing
            violates these Terms and Conditions and may result in, among other things, termination or suspension of your
            rights to use the Site.</p>
        <h2 style="padding-left: 7pt;text-indent: 0pt;line-height: 14pt;text-align: left;">7.0 CONTRIBUTION LICENSE</h2>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">By posting your Contributions to any part of
            the
            Site [or making Contributions accessible to the Site by linking your account from the Site to any of your
            social
            networking accounts], you automatically grant, and you represent and warrant that you have the right to
            grant,
            to us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free,
            fully-paid,
            worldwide right, and license to host, use, copy, reproduce, disclose, sell, resell, publish, broadcast,
            retitle,
            archive, store, cache, publicly perform, publicly display, reformat, translate, transmit, excerpt (in whole
            or
            in part), and distribute such Contributions (including, without limitation, your image and voice) for any
            purpose, commercial, advertising, or otherwise, and to prepare derivative works of, or incorporate into
            other
            works, such Contributions, and grant and authorize sublicenses of the foregoing. The use and distribution
            may
            occur in any media formats and through any media channels.</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">This license will apply to any form, media,
            or
            technology now known or hereafter developed, and includes our use of your name, company name, and franchise
            name, as applicable, and any of the</p>
        <p style="text-indent: 0pt;text-align: left;"><br /></p>
        <p style="padding-left: 5pt;text-indent: 0pt;line-height: 1pt;text-align: left;" />
        <p style="padding-top: 3pt;padding-left: 7pt;text-indent: 0pt;text-align: justify;">trademarks, service marks,
            trade
            names, logos, and personal and commercial images you provide. You waive all moral rights in your
            Contributions,
            and you warrant those moral rights have not otherwise been asserted in your Contributions.</p>
        <p style="text-indent: 0pt;text-align: left;"><br /></p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">We do not assert any ownership over your
            Contributions. You retain full ownership of all of your Contributions and any intellectual property rights
            or
            other proprietary rights associated with your Contributions. We are not liable for any statements or
            representations in your Contributions provided by you in any area on the Site.</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">You are solely responsible for your
            Contributions
            to the Site and you expressly agree to exonerate us from any and all responsibility and to refrain from any
            legal action against us regarding your Contributions. We have no obligation to monitor your Contributions.
        </p>
        <h2 style="padding-left: 7pt;text-indent: 0pt;line-height: 14pt;text-align: left;">8.0 GUIDELINES FOR REVIEWS
        </h2>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: left;">We may provide you areas on the Site to leave
            reviews or ratings. When posting a review, you must comply with the following criteria:</p>
        <ol id="l10">
            <li data-list-text="(1)">
                <p style="padding-left: 22pt;text-indent: -15pt;line-height: 13pt;text-align: left;">you should have
                    firsthand experience with the person/entity being reviewed;</p>
            </li>
            <li data-list-text="(2)">
                <p style="padding-left: 22pt;text-indent: -15pt;line-height: 14pt;text-align: left;">your reviews should
                    not
                    contain offensive profanity, or abusive, racist, offensive, or hate language;</p>
            </li>
            <li data-list-text="(3)">
                <p style="padding-left: 7pt;text-indent: 0pt;text-align: left;">your reviews should not contain
                    discriminatory references based on religion, race, gender, national origin, age, marital status,
                    sexual
                    orientation, or disability;</p>
            </li>
            <li data-list-text="(4)">
                <p style="padding-left: 22pt;text-indent: -15pt;line-height: 13pt;text-align: left;">your reviews should
                    not
                    contain references to illegal activity;</p>
            </li>
            <li data-list-text="(5)">
                <p style="padding-left: 22pt;text-indent: -15pt;line-height: 14pt;text-align: left;">you should not be
                    affiliated with competitors if posting negative reviews;</p>
            </li>
            <li data-list-text="(6)">
                <p style="padding-left: 22pt;text-indent: -15pt;line-height: 14pt;text-align: left;">you should not make
                    any
                    conclusions as to the legality of conduct;</p>
            </li>
            <li data-list-text="(7)">
                <p style="padding-left: 22pt;text-indent: -15pt;line-height: 13pt;text-align: left;">you may not post
                    any
                    false or misleading statements;</p>
            </li>
            <li data-list-text="(8)">
                <p style="padding-left: 22pt;text-indent: -15pt;line-height: 14pt;text-align: left;">you may not
                    organize a
                    campaign encouraging others to post reviews, whether positive or negative.</p>
            </li>
        </ol>
        <p style="text-indent: 0pt;text-align: left;"><br /></p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">We may accept, reject, or remove reviews in
            our
            sole discretion. We have absolutely no obligation to screen reviews or to delete reviews, even if anyone
            considers reviews objectionable or inaccurate. Reviews are not endorsed by us, and do not necessarily
            represent
            our opinions or the views of any of our affiliates or partners.</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">We do not assume liability for any review or
            for
            any claims, liabilities, or losses resulting from any review. By posting a review, you hereby grant to us a
            perpetual, non-exclusive, worldwide, royalty-free, fully-paid, assignable, and sublicensable right and
            license
            to reproduce, modify, translate, transmit by any means, display, perform, and/or distribute all content
            relating
            to reviews.</p>
        <ol id="l11">
            <ol id="l12">
                <li data-list-text="9.0">
                    <h2 style="padding-left: 24pt;text-indent: -17pt;line-height: 14pt;text-align: left;">MOBILE
                        APPLICATION
                        LICENSE</h2>
                </li>
                <li data-list-text="9.1">
                    <h2 style="padding-left: 23pt;text-indent: -16pt;line-height: 13pt;text-align: justify;">Use License
                    </h2>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">If you access the Site via a
                        mobile
                        application, then we grant you a revocable, non-exclusive, non-transferable, limited right to
                        install and use the mobile application on wireless electronic devices owned or controlled by
                        you,
                        and to access and use the mobile application on such devices strictly in accordance with the
                        terms
                        and conditions of this mobile application license contained in these Terms and Conditions.</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;line-height: 13pt;text-align: justify;">You shall not:
                    </p>
                    <ol id="l13">
                        <li data-list-text="(1)">
                            <p style="padding-left: 7pt;text-indent: 0pt;text-align: left;">decompile, reverse engineer,
                                disassemble, attempt to derive the source code of, or decrypt the application;</p>
                        </li>
                        <li data-list-text="(2)">
                            <p style="padding-left: 7pt;text-indent: 0pt;text-align: left;">make any modification,
                                adaptation, improvement, enhancement, translation, or derivative work from the
                                application;
                            </p>
                        </li>
                        <li data-list-text="(3)">
                            <p style="padding-left: 7pt;text-indent: 0pt;text-align: left;">violate any applicable laws,
                                rules, or regulations in connection with your access or use of the application;</p>
                        </li>
                        <li data-list-text="(4)">
                            <p style="padding-left: 7pt;text-indent: 0pt;text-align: left;">remove, alter, or obscure
                                any
                                proprietary notice (including any notice of copyright or trademark) posted by us or the
                                licensors of the application;</p>
                            <p style="text-indent: 0pt;text-align: left;"><br /></p>
                            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 1pt;text-align: left;" />
                        </li>
                        <li data-list-text="(5)">
                            <p style="padding-top: 3pt;padding-left: 7pt;text-indent: 0pt;text-align: justify;">use the
                                application for any revenue generating endeavor, commercial enterprise, or other purpose
                                for
                                which it is not designed or intended;</p>
                        </li>
                        <li data-list-text="(6)">
                            <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">make the application
                                available over a network or other environment permitting access or use by multiple
                                devices
                                or users at the same time;</p>
                        </li>
                        <li data-list-text="(7)">
                            <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">use the application for
                                creating a product, service, or software that is, directly or indirectly, competitive
                                with
                                or in any way a substitute for the application;</p>
                        </li>
                        <li data-list-text="(8)">
                            <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">use the application to
                                send
                                automated queries to any website or to send any unsolicited commercial e-mail;</p>
                        </li>
                        <li data-list-text="(9)">
                            <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">use any proprietary
                                information or any of our interfaces or our other intellectual property in the design,
                                development, manufacture, licensing, or distribution of any applications, accessories,
                                or
                                devices for use with the application.</p>
                        </li>
                    </ol>
                </li>
                <li data-list-text="9.2">
                    <h2 style="padding-left: 24pt;text-indent: -17pt;line-height: 14pt;text-align: justify;">Apple and
                        Android Devices</h2>
                </li>
            </ol>
        </ol>
        <p style="padding-left: 7pt;text-indent: 0pt;line-height: 14pt;text-align: justify;">The following terms apply
            when
            you use a mobile application obtained from either the Apple Store</p>
        <p style="padding-left: 7pt;text-indent: 0pt;line-height: 13pt;text-align: justify;">or Google Play (each an
            “App
            Distributor”) to access the Site:</p>
        <ol id="l14">
            <li data-list-text="(1)">
                <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">the license granted to you for our
                    mobile
                    application is limited to a non-transferable license to use the application on a device that
                    utilizes
                    the Apple iOS or Android operating systems, as applicable, and in accordance with the usage rules
                    set
                    forth in the applicable App Distributor’s terms of service;</p>
            </li>
            <li data-list-text="(2)">
                <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">we are responsible for providing any
                    maintenance and support services with respect to the mobile application as specified in the terms
                    and
                    conditions of this mobile application license contained in these Terms and Conditions or as
                    otherwise
                    required under applicable law, and you acknowledge that each App Distributor has no obligation
                    whatsoever to furnish any maintenance and support services with respect to the mobile application;
                </p>
            </li>
            <li data-list-text="(3)">
                <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">in the event of any failure of the
                    mobile
                    application to conform to any applicable warranty, you may notify the applicable App Distributor,
                    and
                    the App Distributor, in accordance with its terms and policies, may refund the purchase price, if
                    any,
                    paid for the mobile application, and to the maximum extent permitted by applicable law, the App
                    Distributor will have no other warranty obligation whatsoever with respect to the mobile
                    application;
                </p>
            </li>
        </ol>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">(5) you must comply with applicable
            third-party
            terms of agreement when using the mobile application, e.g., if you have a VoIP application, then you must
            not be
            in violation of their wireless data service agreement when using the mobile application;</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">6) you acknowledge and agree that the App
            Distributors are third-party beneficiaries of the terms and conditions in this mobile application license
            contained in these Terms and Conditions, and that each App Distributor will have the right (and will be
            deemed
            to have accepted the right) to enforce the terms and conditions in this mobile application license contained
            in
            these Terms and Conditions against you as a third-party beneficiary thereof.</p>
        <ol id="l15">
            <li data-list-text="10.">
                <h2 style="padding-left: 23pt;text-indent: -16pt;line-height: 13pt;text-align: justify;">SOCIAL MEDIA
                </h2>
                <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">As part of the functionality of the
                    Site,
                    you may link your account with online accounts you have with third-party service providers (each
                    such
                    account, a “Third-Party Account”) by either:</p>
                <ol id="l16">
                    <li data-list-text="(1)">
                        <p style="padding-left: 22pt;text-indent: -15pt;line-height: 13pt;text-align: justify;">
                            providing
                            your Third-Party Account login information through the Site; or</p>
                    </li>
                    <li data-list-text="(2)">
                        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">allowing us to access your
                            Third-Party Account, as is permitted under the applicable terms and conditions that govern
                            your
                            use of each Third-Party Account.</p>
                    </li>
                </ol>
                <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">You represent and warrant that you
                    are
                    entitled to disclose your Third-Party Account login information to us and/or grant us access to your
                    Third-Party Account, without breach by you of any of the terms and conditions that govern your use
                    of
                    the applicable Third-Party Account, and without obligating us to pay any fees or making us subject
                    to
                    any usage limitations imposed by the third-party service provider of the Third-Party Account.</p>
                <p style="padding-left: 7pt;text-indent: 0pt;line-height: 13pt;text-align: justify;">By granting us
                    access
                    to any Third-Party Accounts, you understand that;</p>
                <ol id="l17">
                    <li data-list-text="(1)">
                        <p style="padding-left: 22pt;text-indent: -15pt;line-height: 14pt;text-align: justify;">we may
                            access, make available, and store (if applicable) any content that you have provided to and
                        </p>
                        <p style="text-indent: 0pt;text-align: left;"><br /></p>
                        <p style="padding-left: 5pt;text-indent: 0pt;line-height: 1pt;text-align: left;" />
                        <p
                            style="padding-top: 3pt;padding-left: 7pt;text-indent: 0pt;line-height: 14pt;text-align: left;">
                            stored in your Third-Party Account (the “Social Network Content”) so that it is available on
                            and
                        </p>
                        <p style="padding-left: 7pt;text-indent: 0pt;line-height: 13pt;text-align: left;">through the
                            Site
                            via your account, including without limitation any friend lists and</p>
                    </li>
                    <li data-list-text="(2)">
                        <p style="padding-left: 7pt;text-indent: 0pt;text-align: left;">we may submit to and receive
                            from
                            your Third-Party Account additional information to the extent you are notified when you link
                            your account with the Third-Party Account.</p>
                    </li>
                </ol>
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
                <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Depending on the Third-Party Accounts
                    you
                    choose and subject to the privacy settings that you have set in such Third-Party Accounts,
                    personally
                    identifiable information that you post to your Third-Party Accounts may be available on and through
                    your
                    account on the Site.</p>
                <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Please note that if a Third-Party
                    Account
                    or associated service becomes unavailable or our access to such Third-Party Account is terminated by
                    the
                    third-party service provider, then Social Network Content may no longer be available on and through
                    the
                    Site. You will have the ability to disable the connection between your account on the Site and your
                    Third-Party Accounts at any time.</p>
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
                <h2 style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">PLEASE NOTE THAT YOUR RELATIONSHIP
                    WITH
                    THE THIRD-PARTY SERVICE PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY
                    YOUR
                    AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS.</h2>
                <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">We make no effort to review any
                    Social
                    Network Content for any purpose, including but not limited to, for accuracy, legality, or
                    non-infringement, and we are not responsible for any Social Network Content. You acknowledge and
                    agree
                    that we may access your email address book associated with a Third-Party Account and your contacts
                    list
                    stored on your mobile device or tablet computer solely for purposes of identifying and informing you
                    of
                    those contacts who have also registered to use the Site. You can deactivate the connection between
                    the
                    Site and your Third-Party Account by contacting us using the contact information below or through
                    your
                    account settings (if applicable). We will attempt to delete any information stored on our servers
                    that
                    was obtained through such Third-Party Account, except the username and profile picture that become
                    associated with your account.</p>
            </li>
            <li data-list-text="11.">
                <h2 style="padding-left: 22pt;text-indent: -15pt;line-height: 13pt;text-align: left;">SUBMISSIONS</h2>
                <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">You acknowledge and agree that any
                    questions, comments, suggestions, ideas, feedback, or other information regarding the Site
                    (&quot;Submissions&quot;) provided by you to us are non-confidential and shall become our sole
                    property.
                    We shall own exclusive rights, including all intellectual property rights, and shall be entitled to
                    the
                    unrestricted use and dissemination of these Submissions for any lawful purpose, commercial or
                    otherwise,
                    without acknowledgment or compensation to you.</p>
                <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">You hereby waive all moral rights to
                    any
                    such Submissions, and you hereby warrant that any such Submissions are original with you or that you
                    have the right to submit such Submissions. You agree there shall be no recourse against us for any
                    alleged or actual infringement or misappropriation of any proprietary right in your Submissions.</p>
            </li>
            <li data-list-text="12.">
                <h2 style="padding-left: 23pt;text-indent: -16pt;line-height: 14pt;text-align: left;">THIRD-PARTY
                    WEBSITES
                    AND CONTENT</h2>
                <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">The Site may contain (or you may be
                    sent
                    via the Site) links to other websites (&quot;Third-Party Websites&quot;) as well as articles,
                    photographs, text, graphics, pictures, designs, music, sound, video, information, applications,
                    software, and other content or items belonging to or originating from third parties
                    (&quot;Third-Party
                    Content&quot;).</p>
                <p style="padding-left: 7pt;text-indent: 0pt;text-align: left;">Such Third-Party Websites and
                    Third-Party
                    Content are not investigated, monitored, or checked for accuracy, appropriateness, or completeness
                    by
                    us, and we are not responsible for any Third-Party Websites accessed through the Site or any
                    Third-Party
                    Content posted on, available through, or installed from the Site, including the content, accuracy,
                    offensiveness, opinions, reliability, privacy practices, or other policies of or contained in the
                    Third-Party Websites or the Third-Party Content. Inclusion of, linking to, or permitting the use or
                    installation of any Third-Party Websites or any Third-Party Content does not imply approval or
                    endorsement thereof by us. If you decide to leave the Site</p>
                <p style="text-indent: 0pt;text-align: left;"><br /></p>
                <p style="padding-left: 5pt;text-indent: 0pt;line-height: 1pt;text-align: left;" />
                <p style="padding-top: 3pt;padding-left: 7pt;text-indent: 0pt;text-align: justify;">and access the
                    Third-Party Websites or to use or install any Third-Party Content, you do so at your own risk, and
                    you
                    should be aware these Terms and Conditions no longer govern.</p>
                <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">You should review the applicable
                    terms
                    and policies, including privacy and data gathering practices, of any website to which you navigate
                    from
                    the Site or relating to any applications you use or install from the Site. Any purchases you make
                    through Third-Party Websites will be through other websites and from other companies, and we take no
                    responsibility whatsoever in relation to such purchases which are exclusively between you and the
                    applicable third party.</p>
                <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">You agree and acknowledge that we do
                    not
                    endorse the products or services offered on Third-Party Websites and you shall hold us harmless from
                    any
                    harm caused by your purchase of such products or services. Additionally, you shall hold us harmless
                    from
                    any losses sustained by you or harm caused to you relating to or resulting in any way from any
                    Third-Party Content or any contact with Third-Party Websites.</p>
            </li>
            <li data-list-text="13.">
                <h2 style="padding-left: 23pt;text-indent: -16pt;line-height: 14pt;text-align: left;">ADVERTISERS</h2>
            </li>
        </ol>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">We allow advertisers to display their
            advertisements and other information in certain areas of the Site, such as sidebar advertisements or banner
            advertisements. If you are an advertiser, you shall take full responsibility for any advertisements you
            place on
            the Site and any services provided on the Site or products sold through those advertisements.</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Further, as an advertiser, you warrant and
            represent that you possess all rights and authority to place advertisements on the Site, including, but not
            limited to, intellectual property rights, publicity rights, and contractual rights.</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">[As an advertiser, you agree that such
            advertisements are subject to all laws governing advertisement in Nigeria.] We simply provide the space to
            place
            such advertisements, and we have no other relationship with advertisers.</p>
        <h2 style="padding-left: 7pt;text-indent: 0pt;line-height: 14pt;text-align: left;">12.0 SITE MANAGEMENT</h2>
        <p style="padding-left: 7pt;text-indent: 0pt;line-height: 14pt;text-align: justify;">We reserve the right, but
            not
            the obligation, to:</p>
        <ol id="l18">
            <li data-list-text="(1)">
                <p style="padding-left: 22pt;text-indent: -15pt;line-height: 13pt;text-align: justify;">monitor the Site
                    for
                    violations of these Terms and Conditions;</p>
            </li>
            <li data-list-text="(2)">
                <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">take appropriate legal action against
                    anyone who, in our sole discretion, violates the law or these Terms and Conditions, including
                    without
                    limitation, reporting such user to law enforcement authorities;</p>
            </li>
            <li data-list-text="(3)">
                <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">in our sole discretion and without
                    limitation, refuse, restrict access to, limit the availability of, or disable (to the extent
                    technologically feasible) any of your Contributions or any portion thereof;</p>
            </li>
            <li data-list-text="(4)">
                <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">in our sole discretion and without
                    limitation, notice, or liability, to remove from the Site or otherwise disable all files and content
                    that are excessive in size or are in any way burdensome to our systems;</p>
            </li>
            <li data-list-text="(5)">
                <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">otherwise manage the Site in a manner
                    designed to protect our rights and property and to facilitate the proper functioning of the Site.
                </p>
            </li>
        </ol>
        <h2 style="padding-left: 7pt;text-indent: 0pt;line-height: 14pt;text-align: left;">13.0 PRIVACY POLICY</h2>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">We care about data privacy and security.
            Please
            review our Privacy Policy. By using the Site, you agree to be bound by our Privacy Policy, which is
            incorporated
            into these Terms and Conditions. Please be advised the Site is hosted in the Nigeria.</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">If you access the Site from the European
            Union,
            Asia, or any other region of the world with laws or other requirements governing personal data collection,
            use,
            or disclosure that differ from applicable laws in the Nigeria, then through your continued use of the Site,
            you
            are transferring your data to Nigeria, and you expressly consent to have your data transferred to and
            processed
            in the Nigeria.</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Further, we do not knowingly accept, request,
            or
            solicit information from children or knowingly market to children. Therefore, in accordance with the Data
            Protection laws in Nigeria, if we receive actual knowledge that anyone under the age of 18 has provided
            personal
            information to us without the requisite and verifiable parental consent, we will delete that information
            from
            the Site as quickly</p>
        <p style="text-indent: 0pt;text-align: left;"><br /></p>
        <p style="padding-left: 5pt;text-indent: 0pt;line-height: 1pt;text-align: left;" />
        <p style="padding-top: 3pt;padding-left: 7pt;text-indent: 0pt;line-height: 14pt;text-align: justify;">as is
            reasonably practical.</p>
        <h2 style="padding-left: 7pt;text-indent: 0pt;line-height: 13pt;text-align: left;">14.0 COPYRIGHT INFRINGEMENTS
        </h2>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">We respect the intellectual property rights
            of
            others. If you believe that any material available on or through the Site infringes upon any copyright you
            own
            or control, please immediately notify us using the contact information provided below (a “Notification”). A
            copy
            of your Notification will be sent to the person who posted or stored the material addressed in the
            Notification.
        </p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Please be advised that pursuant to federal
            law
            you may be held liable for damages if you make material misrepresentations in a Notification. Thus, if you
            are
            not sure that material located on or linked to by the Site infringes your copyright, you should consider
            first
            contacting an attorney.</p>
        <h2 style="padding-left: 7pt;text-indent: 0pt;line-height: 13pt;text-align: left;">15.0 TERM AND TERMINATION
        </h2>
        <p class="s1" style="padding-left: 7pt;text-indent: 0pt;text-align: justify;"><span class="p">These Terms and
                Conditions shall remain in full force and effect while you use the Site. </span>WITHOUT<span class="h2">
            </span>LIMITING ANY OTHER PROVISION OF THESE TERMS AND CONDITIONS, WE<span class="h2"> </span>RESERVE THE
            RIGHT
            TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE<span class="h2"> </span>OR LIABILITY, DENY ACCESS TO AND USE
            OF
            THE SITE (INCLUDING<span class="h2"> </span>BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON
            OR<span class="h2"> </span>FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY<span class="h2">
            </span>REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE<span class="h2"> </span>TERMS AND CONDITIONS
            OR
            OF ANY APPLICABLE LAW OR REGULATION. WE<span class="h2"> </span>MAY TERMINATE YOUR USE OR PARTICIPATION IN
            THE
            SITE OR DELETE<span class="h2"> </span>[YOUR ACCOUNT AND] ANY CONTENT OR INFORMATION THAT YOU POSTED<span
                class="h2"> </span>AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.</p>
        <p style="text-indent: 0pt;text-align: left;"><br /></p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">If we terminate or suspend your account for
            any
            reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed
            name,
            or the name of any third party, even if you may be acting on behalf of the third party.</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">In addition to terminating or suspending your
            account, we reserve the right to take appropriate legal action, including without limitation pursuing civil,
            criminal, and injunctive redress.</p>
        <h2 style="padding-left: 7pt;text-indent: 0pt;line-height: 14pt;text-align: left;">16.0 MODIFICATIONS AND
            INTERRUPTIONS</h2>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">We reserve the right to change, modify, or
            remove
            the contents of the Site at any time or for any reason at our sole discretion without notice. However, we
            have
            no obligation to update any information on our Site. We also reserve the right to modify or discontinue all
            or
            part of the Site without notice at any time.</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">We will not be liable to you or any third
            party
            for any modification, price change, suspension, or discontinuance of the Site.</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">We cannot guarantee the Site will be
            available at
            all times. We may experience hardware, software, or other problems or need to perform maintenance related to
            the
            Site, resulting in interruptions, delays, or errors.</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">We reserve the right to change, revise,
            update,
            suspend, discontinue, or otherwise modify the Site at any time or for any reason without notice to you. You
            agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability
            to
            access or use the Site during any downtime or discontinuance of the Site.</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Nothing in these Terms and Conditions will be
            construed to obligate us to maintain and support the Site or to supply any corrections, updates, or releases
            in
            connection therewith.</p>
        <h2 style="padding-left: 7pt;text-indent: 0pt;line-height: 13pt;text-align: left;">17.0 GOVERNING LAW</h2>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">These Terms and Conditions and your use of
            the
            Site are governed by and construed in accordance with the laws of the Federal Republic of Nigeria applicable
            to
            agreements made and to be entirely performed within &amp; outside Nigeria, without regard to its conflict of
            law
            principles.</p>
        <h2 style="padding-left: 7pt;text-indent: 0pt;line-height: 14pt;text-align: left;">18.0 DISPUTE RESOLUTION</h2>
        <p style="text-indent: 0pt;text-align: left;"><br /></p>
        <p style="padding-left: 5pt;text-indent: 0pt;line-height: 1pt;text-align: left;" />
        <p style="padding-top: 3pt;padding-left: 7pt;text-indent: 0pt;text-align: justify;">Any dispute, controversy or
            claim arising out of or in connection with this terms and conditions, including without limitation, any
            question
            as to its construction, existence, validity, interpretation, enforceability or termination which is not
            resolved
            amicably, shall be referred to and finally resolved by arbitration in accordance with the Arbitration and
            Conciliation Act, Cap A18 Laws of the Federation of Nigeria, 2004. The place of arbitration shall be agreed
            by
            both parties and the language to be used in the arbitral proceedings shall be English.</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">There shall be one (1) arbitrator, jointly
            appointed by the Parties. Where Parties are unable to agree on the choice of arbitrator, an application
            shall be
            made to the Chief Judge of the State to make the appointment. The arbitral award shall be final and binding
            on
            the Parties; provided that each party shall retain the right to seek interim, provisional or conservatory
            measures in any court of competent jurisdiction against improper use or disclosure of Confidential
            Information
            and any such request shall not be deemed incompatible with the agreement to arbitrate or a waiver of the
            right
            to arbitrate.</p>
        <h2 style="padding-left: 7pt;text-indent: 0pt;line-height: 13pt;text-align: justify;">19.0 CORRECTIONS</h2>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">There may be information on the Site that
            contains typographical errors, inaccuracies, or omissions that may relate to the Site, including
            descriptions,
            pricing, availability, and various other information. We reserve the right to correct any errors,
            inaccuracies,
            or omissions and to change or update the information on the Site at any time, without prior notice.</p>
        <h2 style="padding-left: 7pt;text-indent: 0pt;line-height: 13pt;text-align: justify;">20.0 DISCLAIMER</h2>
        <h2 style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">THE SITE IS PROVIDED ON AN AS-IS AND
            AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE
            FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE
            AND
            YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR
            COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO
            LIABILITY OR RESPONSIBILITY FOR ANY;</h2>
        <ol id="l19">
            <li data-list-text="(1)">
                <h2 style="padding-left: 23pt;text-indent: -16pt;line-height: 13pt;text-align: justify;">ERRORS,
                    MISTAKES,
                    OR INACCURACIES OF CONTENT AND MATERIALS,</h2>
            </li>
            <li data-list-text="(2)">
                <h2 style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">PERSONAL INJURY OR PROPERTY DAMAGE,
                    OF
                    ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE,</h2>
            </li>
            <li data-list-text="(3)">
                <h2 style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">ANY UNAUTHORIZED ACCESS TO OR USE OF
                    OUR
                    SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN,
                </h2>
            </li>
            <li data-list-text="(4)">
                <h2 style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">ANY INTERRUPTION OR CESSATION OF
                    TRANSMISSION TO OR FROM THE SITE,</h2>
            </li>
            <li data-list-text="(5)">
                <h2 style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">ANY BUGS, VIRUSES, TROJAN HORSES, OR
                    THE
                    LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR</h2>
            </li>
            <li data-list-text="(6)">
                <h2 style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">ANY ERRORS OR OMISSIONS IN ANY
                    CONTENT
                    AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT
                    POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO NOT WARRANT, ENDORSE,
                    GUARANTEE, OR
                    ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE
                    SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER
                    ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY
                    TRANSACTION
                    BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.</h2>
            </li>
        </ol>
        <p style="text-indent: 0pt;text-align: left;"><br /></p>
        <p style="padding-left: 5pt;text-indent: 0pt;line-height: 1pt;text-align: left;" />
        <h2 style="padding-top: 3pt;padding-left: 7pt;text-indent: 0pt;text-align: justify;">AS WITH THE PURCHASE OF A
            PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE
            CAUTION WHERE APPROPRIATE</h2>
        <h2 style="padding-left: 7pt;text-indent: 0pt;line-height: 14pt;text-align: justify;">21.0 LIMITATIONS OF
            LIABILITY
        </h2>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">IN NO EVENT WILL WE OR OUR DIRECTORS,
            EMPLOYEES,
            OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY,
            INCIDENTAL,
            SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING
            FROM
            YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
        <h2 style="padding-left: 7pt;text-indent: 0pt;line-height: 13pt;text-align: justify;">22.0 INDEMNIFICATION</h2>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">You agree to defend, indemnify, and hold us
            harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and
            employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’
            fees
            and expenses, made by any third party due to or arising out of:</p>
        <ol id="l20">
            <li data-list-text="(1)">
                <p style="padding-left: 22pt;text-indent: -15pt;line-height: 13pt;text-align: left;">your Contributions;
                </p>
            </li>
            <li data-list-text="(2)">
                <p style="padding-left: 22pt;text-indent: -15pt;line-height: 14pt;text-align: left;">use of the Site;
                </p>
            </li>
            <li data-list-text="(3)">
                <p style="padding-left: 22pt;text-indent: -15pt;line-height: 13pt;text-align: left;">breach of these
                    Terms
                    and Conditions;</p>
            </li>
            <li data-list-text="(4)">
                <p style="padding-left: 22pt;text-indent: -15pt;line-height: 13pt;text-align: left;">any breach of your
                    representations and warranties set forth in these Terms and Conditions;</p>
            </li>
            <li data-list-text="(5)">
                <p style="padding-left: 7pt;text-indent: 0pt;text-align: left;">your violation of the rights of a third
                    party, including but not limited to intellectual property rights; or</p>
            </li>
            <li data-list-text="(6)">
                <p style="padding-left: 7pt;text-indent: 0pt;text-align: left;">any overt harmful act toward any other
                    user
                    of the Site with whom you connected via the Site. Notwithstanding the foregoing, we reserve the
                    right,
                    at your expense, to assume the exclusive defense and control of any matter for which you are
                    required to
                    indemnify us, and you agree to cooperate, at your expense, with our defense of such claims. We will
                    use
                    reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this
                    indemnification upon becoming aware of it.</p>
            </li>
        </ol>
        <h2 style="padding-left: 10pt;text-indent: 0pt;line-height: 13pt;text-align: justify;">23.0 USER DATA</h2>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">We will maintain certain data that you
            transmit
            to the Site for the purpose of managing the Site, as well as data relating to your use of the Site. Although
            we
            perform regular routine backups of data, you are solely responsible for all data that you transmit or that
            relates to any activity you have undertaken using the Site.</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">You agree that we shall have no liability to
            you
            for any loss or corruption of any such data, and you hereby waive any right of action against us arising
            from
            any such loss or corruption of such data.</p>
        <h2 style="padding-left: 7pt;text-indent: 0pt;line-height: 13pt;text-align: left;">24.0 ELECTRONIC
            COMMUNICATIONS
        </h2>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">When You use the Website or send emails or
            other
            data, information or communication to RENTOUT LTD, you agree and understand that You are communicating with
            RENTOUT LTD through electronic records and You consent to receive communications via electronic records from
            RENTOUT LTD periodically. RENTOUT LTD may communicate with You by email or by such other mode of
            communication,
            electronic or otherwise.</p>
        <h2 style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">YOU HEREBY AGREE TO THE USE OF ELECTRONIC
            SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND
            RECORDS
            OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE.</h2>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">You hereby waive any rights or requirements
            under
            any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original
            signature or delivery or retention of non-electronic records, or to payments or the granting of credits by
            any
            means other than electronic means.</p>
        <h2 style="padding-left: 7pt;text-indent: 0pt;line-height: 13pt;text-align: left;">25.0 MISCELLANEOUS</h2>
        <p style="padding-left: 7pt;text-indent: 0pt;line-height: 14pt;text-align: justify;">These Terms and Conditions
            and
            any policies or operating rules posted by us on the Site constitute</p>
        <p style="text-indent: 0pt;text-align: left;"><br /></p>
        <p style="padding-left: 5pt;text-indent: 0pt;line-height: 1pt;text-align: left;" />
        <p style="padding-top: 3pt;padding-left: 7pt;text-indent: 0pt;text-align: justify;">the entire agreement and
            understanding between you and us. Our failure to exercise or enforce any right or provision of these Terms
            and
            Conditions shall not operate as a waiver of such right or provision.</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">These Terms and Conditions operate to the
            fullest
            extent permissible by law. We may assign any or all of our rights and obligations to others at any time. We
            shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond
            our
            reasonable control.</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">If any provision or part of a provision of
            these
            Terms and Conditions is determined to be unlawful, void, or unenforceable, that provision or part of the
            provision is deemed severable from these Terms and Conditions and does not affect the validity and
            enforceability of any remaining provisions.</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">There is no joint venture, partnership,
            employment or agency relationship created between you and us as a result of these Terms and Conditions or
            use of
            the Site. You agree that these Terms and Conditions will not be construed against us by virtue of having
            drafted
            them.</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">You hereby waive any and all defenses you may
            have based on the electronic form of these Terms and Conditions and the lack of signing by the parties
            hereto to
            execute these Terms and Conditions.</p>
        <ol id="l21">
            <ol id="l22">
                <li data-list-text="26.0">
                    <h2 style="padding-left: 31pt;text-indent: -24pt;line-height: 13pt;text-align: justify;">OUR WEBSITE
                        &amp; SERVICES</h2>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Rentout is a formal online
                        marketplace where customers can rent out their items &amp; services legally owned by them. We
                        provide you a service that you access remotely via the website and application.</p>
                </li>
                <li data-list-text="26.1">
                    <h2 style="padding-left: 43pt;text-indent: -36pt;line-height: 13pt;text-align: justify;">Payment
                    </h2>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">While availing any of the payment
                        method(s) offered at RENTOUT LTD, RENTOUT LTD will not be responsible or assume any liability,
                        whatsoever in respect of any loss or damage arising directly or indirectly to you due to:</p>
                    <ol id="l23">
                        <li data-list-text="1.">
                            <p style="padding-left: 79pt;text-indent: -18pt;line-height: 13pt;text-align: left;">Lack of
                                authorization for any transaction(s), or</p>
                        </li>
                        <li data-list-text="2.">
                            <p style="padding-left: 79pt;text-indent: -18pt;line-height: 13pt;text-align: left;">
                                Exceeding
                                the preset limit mutually agreed by you and between your &quot;Bank(s)&quot;, or</p>
                        </li>
                        <li data-list-text="3.">
                            <p style="padding-left: 79pt;text-indent: -18pt;line-height: 14pt;text-align: left;">Any
                                payment
                                issues arising out of the transaction, or</p>
                        </li>
                        <li data-list-text="4.">
                            <p style="padding-left: 79pt;text-indent: -18pt;line-height: 14pt;text-align: left;">Decline
                                of
                                transaction for any other reason/s.</p>
                            <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">All payments made against
                                the
                                purchases/services on RENTOUT LTD by you shall be compulsorily in Nigerian Naira
                                acceptable
                                by the Central Bank of Nigeria. RENTOUT LTD does not accept any other form of currency
                                with
                                respect to the purchases made on RENTOUT LTD’s website.</p>
                        </li>
                    </ol>
                </li>
                <li data-list-text="26.2">
                    <h2 style="padding-left: 29pt;text-indent: -22pt;line-height: 14pt;text-align: justify;">
                        Cancellation of
                        order</h2>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">RENTOUT LTD reserves the right to
                        cancel any order without any explanation for doing so, under situation where RENTOUT LTD is not
                        able
                        to meet the requirement of the order placed or order so placed/cancelled does not comply with
                        the
                        RENTOUT LTD policy or for any reason. However, RENTOUT LTD will ensure that any communication of
                        cancellation of an order is intimated within appropriate time to the concerned person and any
                        applicable refund, will be made in reasonable time. Note that in the event of cancellation of a
                        ‘pay
                        on delivery’ orders, RENTOUT LTD will not be liable in any form where the customer proceeds to
                        receive orders following an earlier cancellation.</p>
                </li>
                <li data-list-text="26.3">
                    <h2 style="padding-left: 43pt;text-indent: -36pt;line-height: 13pt;text-align: justify;">Termination
                        of
                        Service</h2>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">RENTOUT LTD reserves the absolute
                        right to modify, discontinue, temporarily or permanently, any and all portion of our goods and
                        services with or without prior communications. You hereby consent that RENTOUT LTD will under no
                        circumstance be liable to you or any third party for any modification or discontinuance of
                        availability of goods or services on the website.</p>
                </li>
                <li data-list-text="26.4">
                    <h2 style="padding-left: 29pt;text-indent: -22pt;line-height: 13pt;text-align: justify;">Wallet</h2>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">The Wallet (&quot;Wallet&quot;)
                        is a
                        pre-paid payment instrument which is associated with your RENTOUT LTD account. You can maintain
                        a
                        balance of money with RENTOUT LTD and redeem it at your convenience.</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;line-height: 14pt;text-align: justify;">RENTOUT LTD
                        will
                        not pay any interest on the amount maintained by you in your Wallet.</p>
                    <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    <p style="padding-left: 5pt;text-indent: 0pt;line-height: 1pt;text-align: left;" />
                    <p style="padding-top: 3pt;padding-left: 7pt;text-indent: 0pt;text-align: justify;">Refill of
                        Wallet:
                        Your Wallet can be refilled, using any of the pre-paid payment options only. You will be able to
                        recharge your Wallet up to an amount of N500,000 in one instance. There is no need to maintain a
                        minimum balance.</p>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Limitation of Liability: In no
                        event
                        will RENTOUT LTD be liable for any special, incidental, indirect or consequential damages or
                        losses
                        of any kind, arising from the use of Wallet or association with the Wallet.</p>
                </li>
                <li data-list-text="26.5">
                    <h2 style="padding-left: 29pt;text-indent: -22pt;line-height: 13pt;text-align: justify;">Breach</h2>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">Without limiting other remedies,
                        RENTOUT LTD may limit your activity, immediately remove your information, warn other Users of
                        your
                        actions, immediately temporarily/indefinitely suspend or terminate or block your membership,
                        and/or
                        refuse to provide you with access to RENTOUT LTD website in the event, but not limited to:</p>
                    <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    <ul id="l24">
                        <li data-list-text="">
                            <p style="padding-left: 43pt;text-indent: -18pt;text-align: left;">If you breach the Terms
                                of
                                Use or Privacy Policy or other rules and policies, if any;</p>
                        </li>
                        <li data-list-text="">
                            <p style="padding-left: 43pt;text-indent: -18pt;text-align: left;">If RENTOUT LTD is unable
                                to
                                verify or authenticate any information you provide; or</p>
                        </li>
                        <li data-list-text="">
                            <p style="padding-left: 43pt;text-indent: -18pt;text-align: left;">If it is believed that
                                your
                                actions may cause legal liability for you, other Users or RENTOUT LTD</p>
                        </li>
                    </ul>
                    <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">RENTOUT LTD may at any time at
                        its
                        sole discretion reinstate suspended users. A User that has been suspended or blocked may not
                        register or attempt to register with RENTOUT LTD or use RENTOUT LTD Website in any manner
                        whatsoever
                        until such time that such User is reinstated by RENTOUT LTD. Notwithstanding the foregoing, if
                        you
                        breach the Terms of Use or Privacy Policy or other rules and policies, RENTOUT LTD reserves the
                        right to recover any amounts due and owing by you to RENTOUT LTD and to take strict legal action
                        including but not limited to a referral to the appropriate law enforcement or other authorities
                        for
                        initiating criminal or other proceedings against you.</p>
                </li>
                <li data-list-text="26.6">
                    <h2 style="padding-left: 29pt;text-indent: -22pt;line-height: 13pt;text-align: justify;">Refund
                        policy
                    </h2>
                </li>
            </ol>
        </ol>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: justify;">RENTOUT LTD offers a <u>&nbsp;&nbsp; </u>day
            return window for items that meet the requirements for return. Please contact us via any of our contact
            channels
            within seven days of delivery and we will ensure you get resolution.</p>
        <h2 style="padding-left: 7pt;text-indent: 0pt;line-height: 13pt;text-align: left;">27.0 CONTACT US</h2>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: left;">In order to resolve a complaint regarding the
            Site
            or to receive further information regarding use of the Site, please contact us at:</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: left;">[Rentout Ltd] [Corporate Address]</p>
        <p style="padding-left: 7pt;text-indent: 0pt;text-align: left;">[Corporate Phone Number] [Corporate Fax Number]
            [Email Address]</p>
        <p style="text-indent: 0pt;text-align: left;"><br /></p>

    </div>
</template>

<script>
export default {
    name: '',
    components: {

    },
    data() {
        return {
            headerClass: "text-sg-primary font-1-e-bold h6",
            paragraph: "text-start my-2 text-sm-sm"
        }
    }
}
</script>

<style lang="less" scoped>
* {
    margin: 0;
    padding: 0;
    text-indent: 0;
}

.h2,
h2 {
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 12pt;
}

.p,
p {
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
    margin: 0pt;
}

.s1 {
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: underline;
    font-size: 12pt;
}

li {
    display: block;
}

#l1 {
    padding-left: 0pt;
    counter-reset: c1 3;
}

#l1>li>*:first-child:before {
    counter-increment: c1;
    content: counter(c1, decimal)" ";
    color: black;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
}

#l1>li:first-child>*:first-child:before {
    counter-increment: c1 0;
}

#l2 {
    padding-left: 0pt;
    counter-reset: c2 0;
}

#l2>li>*:first-child:before {
    counter-increment: c2;
    content: counter(c1, decimal)"." counter(c2, decimal)" ";
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 12pt;
}

#l2>li:first-child>*:first-child:before {
    counter-increment: c2 0;
}

#l3 {
    padding-left: 0pt;
}

#l3>li>*:first-child:before {
    content: " ";
    color: black;
    font-family: Symbol, serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

li {
    display: block;
}

#l4 {
    padding-left: 0pt;
    counter-reset: d1 5;
}

#l4>li>*:first-child:before {
    counter-increment: d1;
    content: counter(d1, decimal)" ";
    color: black;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
}

#l4>li:first-child>*:first-child:before {
    counter-increment: d1 0;
}

#l5 {
    padding-left: 0pt;
    counter-reset: d2 0;
}

#l5>li>*:first-child:before {
    counter-increment: d2;
    content: counter(d1, decimal)"." counter(d2, decimal)" ";
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 12pt;
}

#l5>li:first-child>*:first-child:before {
    counter-increment: d2 0;
}

#l6 {
    padding-left: 0pt;
    counter-reset: d3 1;
}

#l6>li>*:first-child:before {
    counter-increment: d3;
    content: counter(d3, decimal)". ";
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#l6>li:first-child>*:first-child:before {
    counter-increment: d3 0;
}

li {
    display: block;
}

#l7 {
    padding-left: 0pt;
    counter-reset: e1 6;
}

#l7>li>*:first-child:before {
    counter-increment: e1;
    content: counter(e1, decimal)" ";
    color: black;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
}

#l7>li:first-child>*:first-child:before {
    counter-increment: e1 0;
}

#l8 {
    padding-left: 0pt;
    counter-reset: e2 0;
}

#l8>li>*:first-child:before {
    counter-increment: e2;
    content: counter(e1, decimal)"." counter(e2, decimal)" ";
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 12pt;
}

#l8>li:first-child>*:first-child:before {
    counter-increment: e2 0;
}

#l9 {
    padding-left: 0pt;
    counter-reset: e3 1;
}

#l9>li>*:first-child:before {
    counter-increment: e3;
    content: counter(e3, decimal)". ";
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#l9>li:first-child>*:first-child:before {
    counter-increment: e3 0;
}

li {
    display: block;
}

#l10 {
    padding-left: 0pt;
    counter-reset: f1 1;
}

#l10>li>*:first-child:before {
    counter-increment: f1;
    content: "(" counter(f1, decimal)") ";
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#l10>li:first-child>*:first-child:before {
    counter-increment: f1 0;
}

li {
    display: block;
}

#l11 {
    padding-left: 0pt;
    counter-reset: g1 9;
}

#l11>li>*:first-child:before {
    counter-increment: g1;
    content: counter(g1, decimal)" ";
    color: black;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
}

#l11>li:first-child>*:first-child:before {
    counter-increment: g1 0;
}

#l12 {
    padding-left: 0pt;
    counter-reset: g2 0;
}

#l12>li>*:first-child:before {
    counter-increment: g2;
    content: counter(g1, decimal)"." counter(g2, decimal)" ";
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 12pt;
}

#l12>li:first-child>*:first-child:before {
    counter-increment: g2 0;
}

#l13 {
    padding-left: 0pt;
    counter-reset: h2 1;
}

#l13>li>*:first-child:before {
    counter-increment: h2;
    content: "(" counter(h2, decimal)") ";
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#l13>li:first-child>*:first-child:before {
    counter-increment: h2 0;
}

li {
    display: block;
}

#l14 {
    padding-left: 0pt;
    counter-reset: i1 1;
}

#l14>li>*:first-child:before {
    counter-increment: i1;
    content: "(" counter(i1, decimal)") ";
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#l14>li:first-child>*:first-child:before {
    counter-increment: i1 0;
}

li {
    display: block;
}

#l15 {
    padding-left: 0pt;
    counter-reset: j1 10;
}

#l15>li>*:first-child:before {
    counter-increment: j1;
    content: counter(j1, decimal)". ";
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 12pt;
}

#l15>li:first-child>*:first-child:before {
    counter-increment: j1 0;
}

#l16 {
    padding-left: 0pt;
    counter-reset: k1 1;
}

#l16>li>*:first-child:before {
    counter-increment: k1;
    content: "(" counter(k1, decimal)") ";
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#l16>li:first-child>*:first-child:before {
    counter-increment: k1 0;
}

#l17 {
    padding-left: 0pt;
    counter-reset: l1 1;
}

#l17>li>*:first-child:before {
    counter-increment: l1;
    content: "(" counter(l1, decimal)") ";
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#l17>li:first-child>*:first-child:before {
    counter-increment: l1 0;
}

li {
    display: block;
}

#l18 {
    padding-left: 0pt;
    counter-reset: m1 1;
}

#l18>li>*:first-child:before {
    counter-increment: m1;
    content: "(" counter(m1, decimal)") ";
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#l18>li:first-child>*:first-child:before {
    counter-increment: m1 0;
}

li {
    display: block;
}

#l19 {
    padding-left: 0pt;
    counter-reset: n1 1;
}

#l19>li>*:first-child:before {
    counter-increment: n1;
    content: "(" counter(n1, decimal)") ";
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 12pt;
}

#l19>li:first-child>*:first-child:before {
    counter-increment: n1 0;
}

li {
    display: block;
}

#l20 {
    padding-left: 0pt;
    counter-reset: o1 1;
}

#l20>li>*:first-child:before {
    counter-increment: o1;
    content: "(" counter(o1, decimal)") ";
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#l20>li:first-child>*:first-child:before {
    counter-increment: o1 0;
}

li {
    display: block;
}

#l21 {
    padding-left: 0pt;
    counter-reset: p1 26;
}

#l21>li>*:first-child:before {
    counter-increment: p1;
    content: counter(p1, decimal)" ";
    color: black;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
}

#l21>li:first-child>*:first-child:before {
    counter-increment: p1 0;
}

#l22 {
    padding-left: 0pt;
    counter-reset: p2 0;
}

#l22>li>*:first-child:before {
    counter-increment: p2;
    content: counter(p1, decimal)"." counter(p2, decimal)" ";
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 12pt;
}

#l22>li:first-child>*:first-child:before {
    counter-increment: p2 0;
}

#l23 {
    padding-left: 0pt;
    counter-reset: p3 1;
}

#l23>li>*:first-child:before {
    counter-increment: p3;
    content: counter(p3, decimal)". ";
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#l23>li:first-child>*:first-child:before {
    counter-increment: p3 0;
}

#l24 {
    padding-left: 0pt;
}

#l24>li>*:first-child:before {
    content: " ";
    color: black;
    font-family: Symbol, serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

/* My Overwrite css classes */

h2,
h2::before {
    color: var(--softgreen-orange) !important;
    font-family: font-1 !important;
    margin-top: 20px;
    margin-bottom: 10px;
}

b,
p,
p::before,
span,
a {
    font-family: font-1 !important;
}

b {
    color: var(--softgreen-yellow) !important;
}
</style>
